import { CREATE_TICKET } from '@/config/endpoint';
import Req from './AxiosRequest';

class Profile {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	createTicket(payload) {
		const { method, endpoint } = CREATE_TICKET;
		this.options.data = payload;
		// this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return this.data.postFormData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Profile;
