<template>
	<form
		@submit.prevent="preparePayload"
		@keydown="clearError($event.target.name);"
		@change="clearError($event.target.name);">
		<div class="row">
			<div class="col-12 col-md-6">
				<input-text
					id="name"
					:errors="errors.errors['name']"
					:disabled="true"
					:label="translate('name')"
					:setter-value="form.name"
					:required="true"
					type="text"
					@dataChanged="form.name = $event" />
			</div>
			<div class="col-12 col-md-6">
				<input-text
					id="email"
					:errors="errors.errors['email']"
					:disabled="true"
					:label="translate('email')"
					:setter-value="form.email"
					:required="true"
					type="text"
					@dataChanged="form.email = $event" />
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6">
				<input-text
					id="subject"
					:errors="errors.errors['subject']"
					:label="translate('subject')"
					:setter-value="form.subject"
					:required="true"
					type="text"
					@dataChanged="form.subject = $event" />
			</div>
			<div class="col-12 col-md-6">
				<div class="form-group">
					<label
						for="type"
						class="label">
						{{ translate('request_type') }}<span class="text-danger"> *</span>
					</label>
					<select
						id="type"
						v-model="form.type"
						:class="hasError('type') ? 'is-invalid' : ''"
						name="type"
						class="form-control">
						<option value="">
							{{ translate('select_request') }}
						</option>
						<option
							v-for="item in requests"
							:key="item"
							:value="item">
							{{ translate(item) }}
						</option>
					</select>
					<template v-if="typeof errors.errors['type'] !== 'undefined'">
						<span
							v-for="error in errors.errors['type']"
							:key="error"
							class="custom-invalid-feedback animated fadeIn text-danger"
							v-text="error" />
					</template>
				</div>
			</div>
			<!-- <div class="col-12 col-md-6">
				<b-form-group>
					<label
						for="type"
						class="label">{{ translate('file') }}
						<span class="text-danger">*</span>
					</label>
					<b-form-file
						id="file"
						ref="file"
						name="file"
						type="file" />
					<b-form-text id="file">
						{{ translate('max', {size: maxMB }) }}
					</b-form-text>
				</b-form-group>
			</div> -->
		</div>
		<div class="row">
			<div class="col-12">
				<input-text
					id="message"
					:errors="errors.errors['message']"
					:label="translate('message')"
					:setter-value="form.message"
					:required="true"
					type="textarea"
					@dataChanged="form.message = $event" />
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<button
					:disabled="!!Object.keys(errors.errors).length || loading"
					class="btn btn-primary float-right">
					<template v-if="loading">
						<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
					</template>
					<template v-else>
						{{ translate('submit') }}
					</template>
				</button>
			</div>
		</div>
	</form>
</template>

<script>

import InputText from '@/components/InputText';
import { REQUESTS, MAX_MB } from '@/settings/Support';
import Support from '@/util/Support';
import { Support as SupportMessages, Validations } from '@/translations';

export default {
	name: 'Support',
	messages: [SupportMessages, Validations],
	components: {
		InputText,
	},
	data() {
		return {
			form: {
				username: '',
				user_id: '',
				name: '',
				email: '',
				subject: '',
				type: '',
				message: '',
				file: '',
			},
			requests: REQUESTS,
			maxMB: MAX_MB,
			alert: new this.$Alert(),
			support: new Support(),
		};
	},
	computed: {
		errors() {
			return this.support.data.errors;
		},
		loading() {
			return !!this.support.data.loading;
		},
	},
	mounted() {
		this.form.name = this.$user.details().fullname;
		this.form.email = this.$user.details().email;
		this.form.user_id = this.$user.details().id;
		this.form.username = this.$user.details().username;
	},
	methods: {
		clearError(field) {
			if (field) {
				delete this.support.data.errors.errors[field];
				this.support.data.errors.errors = { ...this.errors.errors };
			}
		},
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') { return true; }
			return false;
		},
		preparePayload() {
			const options = {
				config: {
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: this.translate('understand'),
					cancelButtonText: this.translate('cancel'),
					reverseButtons: true,
					allowOutsideClick: false,
					allowEscapeKey: false,
				},
			};

			this.alert.confirmation(this.translate('important'), this.translate('confirmation_text'), options, true).then(() => {
				const formData = new FormData();

				formData.set('name', this.form.name);
				formData.set('user_id', this.form.user_id);
				formData.set('subject', this.form.subject);
				formData.set('type', this.form.type);
				formData.set('message', this.form.message);
				this.submitTicket(formData);
			}).catch(() => {});
		},
		submitTicket(payload) {
			this.support.createTicket(payload).then(() => {
				this.form.subject = '';
				this.form.type = '';
				this.form.message = '';
				this.alert.toast('success', this.translate('ticket_created'));
			}).catch(() => {
				this.data = payload;
			}).finally(() => {
				this.support.data.loading = false;
			});
		},
	},

};
</script>
